import { IApiService } from "@/app/services/apiServiceProvider";
import { app } from "../../app.module";
import { SESSION_AO_CODE_KEY } from "../../configuration";

const serviceId = "accountOwnerRegisterInitialise";

app.factory(serviceId, accountOwnerRegisterInitialise);

function accountOwnerRegisterInitialise($q, $route, $location, $rootScope, apiService: IApiService, localStorageService) {
    "ngInject";
    return function () {
        var deferred = $q.defer();

        var dvsUserId = $route.current.params.userId;
        var sessionId = localStorageService.get(SESSION_AO_CODE_KEY);

        if (!dvsUserId || !sessionId) {
            $location.path("/");
            deferred.reject();
        } else {
            $rootScope.showFullScreenLoadingPane();

            apiService
                .accountOwnerActivate({ sessionId: sessionId, userId: dvsUserId })
                .then(response => {
                    deferred.resolve(response.data);
                })
                .catch(() => {
                    deferred.reject();
                })
                .finally(() => {
                    $rootScope.hideFullScreenLoadingPane();
                });
        }

        return deferred.promise;
    };
}
