import "angular";
import "angular-animate";
import "angular-route";
import "angular-cookies";
import "angular-resource";
import "angular-sanitize";
import "angular-local-storage";
import "angular-loading-bar";
import "moment";
import "pikaday";
import "angular-scroll";
import "angular-toastr";

require("../../node_modules/AngularJS-Toaster/toaster.js");

require("../vendor/angular-pikaday/angular-pikaday.js");
require("../vendor/angular-mask/mask.js");

require("../vendor/angular-highcharts/highcharts-ng.js");

require("../vendor/isteven-multi-select/isteven-multi-select.js");

require("../vendor/modernizr/modernizr.min.js");
