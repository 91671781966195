import { app as services } from "../app.module";
import { Student } from "../models/student";
import { IDataContext } from "./dataContext";
import { StudentFeature, EnrolmentFeature } from "../configuration";
import { Enrolment } from "../models/enrolment";
import { StudentContext } from "./contextLoader";
import { IApiService } from "./apiServiceProvider";

export class AuthorisationService {
    constructor(private $location: ng.ILocationService, private dataContext: IDataContext, private apiService: IApiService) {
        "ngInject";
    }

    hasStudentFeatureAccess(context: StudentContext, feature: StudentFeature) {
        if (this.invalidStudent(context.student)) return false;
        if (this.unauthorizedStudentFeature(context.student, feature)) return false;

        return true;
    }

    hasFeatureAccess(context: StudentContext, feature: EnrolmentFeature) {
        if (this.invalidStudent(context.student)) return false;
        if (this.invalidEnrolment(context.enrolment)) return false;
        if (this.unauthorizedEnrolmentFeature(context.enrolment, feature)) return false;

        return true;
    }

    logout(toUrl?: string | null | undefined) {
        var wasAdmin = this.dataContext.isAdminView();
        var logout = wasAdmin ? this.apiService.adminViewLogout() : this.apiService.logout();

        return logout.then(() => {
            this.clearAuthenticationCookie().then(() => {
                if (wasAdmin) {
                    this.$location.path("/admin-view/logout");
                } else {
                    this.$location.path(toUrl || "/login").search("logout", "true");
                }
            });
        });
    }

    clearAuthenticationCookie() {
        return this.dataContext.flagSignedOut(false);
    }

    clearAuthenticationCookieWithoutPublishing() {
        return this.dataContext.flagSignedOutWithoutRaisingEvent();
    }

    private invalidEnrolment(enrolment: Enrolment) {
        if (!enrolment) {
            this.$location.path("/studentNotFound");
            return true;
        }
        return false;
    }

    private invalidStudent(student: Student) {
        if (!student) {
            this.$location.path("/studentNotFound");
            return true;
        }
        return false;
    }

    private unauthorizedStudentFeature(student: Student, feature: StudentFeature) {
        if (!student.isFeatureAvailable(feature)) {
            var newPath = "/featureUnavailable";
            this.$location.path(newPath);
            return true;
        }
        return false;
    }

    private unauthorizedEnrolmentFeature(enrolment: Enrolment, feature: EnrolmentFeature) {
        if (!enrolment.isFeatureAvailable(feature)) {
            var newPath = "/featureUnavailable";
            this.$location.path(newPath);
            return true;
        }
        return false;
    }
}

var serviceId = "authorisationService";
services.service(serviceId, AuthorisationService);
